import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Select, message } from "antd";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import {
  ChangeTransactionTable,
  getDiningTables,
} from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";

const UpdateTable = ({ order, reloadCallback }) => {
  const { authUser } = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [diningTables, setDiningTables] = useState([]);
  const { t } = useTranslation();
  const [UpdateDiningTableForm] = Form.useForm();

  useEffect(() => {
    setLoadingBTN(true);
    getDiningTables()
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setDiningTables(response.data.data.dining_tables);
        }
      })
      .finally(() => {
        setLoadingBTN(false);
      });
  }, []);

  const showEditModel = () => {
    // Set Form Fields
    UpdateDiningTableForm.setFieldsValue({
      id: order.id,
      dining_table_id: order.dining_table?.id,
    });
    setModalVisible(true);
  };

  const handleUpdateTable = () => {
    UpdateDiningTableForm.validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        // Sending Request to API
        ChangeTransactionTable(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("table_updated"),
                response.data.message
              );
              reloadCallback();
            } else {
              // Error
              let errorMessages = Object.values(data.data.errors);
              errorMessages.forEach(function (key, value) {
                if (key.message) {
                  message.error(`Error in Row ${key.row}: ${key.message}`);
                } else {
                  let temp = Object.values(key);
                  message.error(temp[0]);
                }
              });
            }
            // Hide Modal
            setModalVisible(false);
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoadingBTN(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Button type="primary" onClick={showEditModel}>
        {order.dining_table ? t("update_table") : t("assign_table_to_order")}
      </Button>

      <Modal
        title={order.dining_table ? t("update_table") : t("add_table")}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={handleUpdateTable}
        footer={[
          <Button
            key="back"
            loading={loadingBTN}
            onClick={() => setModalVisible(false)}
          >
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingBTN}
            onClick={handleUpdateTable}
          >
            {order.dining_table ? t("update") : t("add")}
          </Button>,
        ]}
      >
        <Form
          name="assign-table-form"
          layout="vertical"
          onFinish={handleUpdateTable}
          style={{ width: "100%" }}
          form={UpdateDiningTableForm}
        >
          <Form.Item
            name="dining_table_id"
            label={t("select_table")}
            rules={[
              {
                required: true,
                message: t("validate_table_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              allowClear
              options={diningTables
                .filter((table) => {
                  if (table.branch.id === authUser.branch_id) {
                    return table;
                  } else return null;
                })
                .map((table) => {
                  return {
                    label: table.name,
                    value: table.id,
                  };
                })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateTable;
