import "../AuthStyle.css";
import { Form, Input, message, Button, Dropdown } from "antd";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  USER_TYPE_BUSINESS_OWNER,
  USER_TYPE_EMPLOYEE,
} from "../../../configs/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DownOutlined } from "@ant-design/icons";

const Login = () => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const { setAuthUser, setFullPageLoading } = useContext(AppContext);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (values) => {
    setFullPageLoading(true);
    userLogin(values)
      .then((response) => {
        if (
          response.data &&
          response.data.success &&
          (response.data.data.user.type === USER_TYPE_BUSINESS_OWNER ||
            response.data.data.user.type === USER_TYPE_EMPLOYEE)
        ) {
          localStorage.setItem(
            "access_token",
            response.data.data.user.access_token
          );
          setAuthUser(response.data.data.user);
          navigate("/home");
        } else {
          let error = response.response;
          if (error?.status === 422) {
            message.error(error.data.message);
          } else if (error?.status === 500) {
            let errorMessages = Object.values(error.data.data.errors);
            errorMessages.forEach(function (key, value) {
              let temp = Object.values(key);
              message.error(temp[0]);
            });
          } else {
            message.error(t("something_went_wrong"));
          }
        }
      })
      .finally(() => {
        setFullPageLoading(false);
      });
  };

  const LangOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "ltr" });
          }}
        >
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "rtl" });
          }}
        >
          عربي
        </div>
      ),
    },
  ];

  return (
    <>
      <header className="header">
        <div
          className="main-wrapper"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            <img
              src="/anypos-logo.svg"
              alt="anypos"
              style={{ width: "170px", height: "auto" }}
            />
          </div>
          <div
            className="Button-area"
            style={{ display: "flex", columnGap: "10px" }}
          >
            <a href="https://anypos.app/" target="_blank" rel="noreferrer">
              <Button className="border_btn">{t("visit_website")}</Button>
            </a>
            <Link to="/register">
              <Button className="Solid_btn1">{t("sign_up")}</Button>
            </Link>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: LangOptions,
              }}
            >
              <Button className="Solid_btn2">
                {appConfig.direction === "ltr" ? "English" : "عربي"}
                <span
                  style={{
                    margin:
                      appConfig.direction === "ltr" ? "0 0 0 8px" : "0 8px 0 0",
                  }}
                >
                  <DownOutlined
                    style={{
                      fontSize: "12px",
                    }}
                  />
                </span>
              </Button>
            </Dropdown>
          </div>
        </div>
      </header>
      <div
        className="register_area"
        style={{ backgroundColor: "#F8F9FF", overflowX: "hidden" }}
      >
        <Form
          name="login-user-form"
          layout="vertical"
          onFinish={onFinish}
          style={{
            maxWidth: "300px",
            Width: "100%",
            margin: "20px auto 0",
          }}
        >
          <h2 className="authSection-heading">{t("login")}</h2>
          <Form.Item
            name="phone"
            label={t("phone_number") + " eg: +966 5xxxxxxx"}
            rules={[
              {
                required: true,
                message: t("validate_phone_required"),
              },
              {
                min: 12,
                message: t("validate_phone_exact_limit"),
              },
              {
                max: 12,
                message: t("validate_phone_exact_limit"),
              },
            ]}
          >
            <PhoneInput
              localization={["sa"]}
              country={"sa"}
              onlyCountries={["sa"]}
              masks={{ sa: "(...) ..-..-.." }}
              placeholder="+966 (123) 45-67-89"
              style={{
                width: "100%",
                direction: "ltr",
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("validate_password_required"),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ margin: "20px 0" }}>
            <Button size="large" block type="primary" htmlType="submit">
              {t("login")}
            </Button>
          </Form.Item>
        </Form>
        <center>
          <Link to="/forgot">{t("forgot_password")}</Link>
        </center>
      </div>
    </>
  );
};

export default Login;
