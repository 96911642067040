import "./Pricing.css";
import { useState, useEffect } from "react";
import { Row, Col, Button, Typography, Alert, Tooltip, Tag } from "antd";
import { currencyFormatter, findPercentage } from "../../utils";
import {
  PERIOD_DAILY,
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
} from "../../configs/constants";
import {
  getAllAddons,
  getSubscriptionPlansAsObject,
} from "../../network/network";
// import { AppContext } from "../../context/AppContextProvider";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CurrencyFormatter from "../CurrencyFormatter";
import NotificationWithIcon from "../NotificationWithIcon";

const { Text } = Typography;

const Pricing = ({ callbackSelection }) => {
  // const { displayWebView } = useContext(AppContext);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [usersCount, setUsersCount] = useState(1);
  const [userAmount, setUserAmount] = useState(0);
  const [userDiscount, setUserDiscount] = useState(0);
  const [planAmount, setPlanAmount] = useState(0);
  const [planDiscount, setPlanDiscount] = useState(0);
  const [addonsData, setAddonsData] = useState([]);
  const { t } = useTranslation();
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonsTotal, setAddonsTotal] = useState(0);

  useEffect(() => {
    getSubscriptionPlansAsObject().then((res) => {
      if (res.data && res.data.success) {
        setSubscriptionPlans(res.data.data.subscription_plans);
        setSelectedPeriod(
          res.data.data.subscription_plans.find(
            (p) => p.period === PERIOD_DAILY
          )
            ? PERIOD_DAILY
            : PERIOD_YEARLY
        );
      }
    });
  }, []);

  useEffect(() => {
    getAllAddons().then((response) => {
      if (response.data && response.data.success) {
        setAddonsData(response.data.data.addons);
      }
    });
  }, []);

  useEffect(() => {
    selectedPeriod && calculateAmount();
  });

  // Use useEffect to update the Addons total price whenever selectedAddons changes
  useEffect(() => {
    const newTotalPrice = calculateTotalPrice(selectedAddons);
    setAddonsTotal(newTotalPrice);
  }, [selectedAddons]);

  const findPlan = (period) => {
    if (!subscriptionPlans.length) {
      return false;
    } else {
      return subscriptionPlans.find((p) => p.period === period);
    }
  };

  const calculateAmount = () => {
    let plan;
    if (selectedPeriod === PERIOD_DAILY) {
      plan = findPlan(PERIOD_DAILY);
    } else if (selectedPeriod === PERIOD_MONTHLY) {
      plan = findPlan(PERIOD_MONTHLY);
    } else {
      plan = findPlan(PERIOD_YEARLY);
    }

    setPlanAmount(plan.price);
    setPlanDiscount(plan.discount);
    setUserAmount(plan.user_price);
    setUserDiscount(plan.user_discount);
  };

  const calculateTotalPrice = (selectedAddons) => {
    return selectedAddons.reduce((total, addon) => {
      if (addon.total_trial_days > 0) {
        // If the addon has trial days, its price is 0
        return total + 0;
      } else if (addon.discount > 0) {
        // If the addon has a discount, subtract the discount from the price
        return total + (Number(addon.price) - Number(addon.discount));
      } else {
        // Otherwise, use the full price
        return total + Number(addon.price);
      }
    }, 0);
  };

  const addAddon = (addon) => {
    if (addon.dependent_addons.length > 0) {
      const missingAddons = addon.dependent_addons.filter(
        (dependentAddon) =>
          !selectedAddons.find((selected) => selected.id === dependentAddon.id)
      );

      if (missingAddons.length > 0) {
        const missingNames = missingAddons.map((a) => a.name).join(", ");
        NotificationWithIcon(
          "error",
          t("adding_dependencies"),
          `${addon.name} depends on ${missingNames}. Please select ${
            missingAddons.length > 1 ? "those" : "that"
          } first.`
        );
        return;
      }
    }
    setSelectedAddons([...selectedAddons, addon]);
  };

  const removeAddon = (addon) => {
    if (addon.required_by_addons.length > 0) {
      const dependentAddons = selectedAddons.filter((selected) =>
        addon.required_by_addons.some((req) => req.id === selected.id)
      );

      if (dependentAddons.length > 0) {
        const dependentNames = dependentAddons.map((a) => a.name).join(", ");
        NotificationWithIcon(
          "warning",
          t("removing_dependencies"),
          `${dependentNames} ${
            dependentAddons.length > 1 ? "depend" : "depends"
          } on ${addon.name}. ${
            dependentAddons.length > 1 ? "These" : "It"
          } will be removed as well.`
        );
        setSelectedAddons(
          selectedAddons.filter(
            (selected) =>
              !dependentAddons.includes(selected) && selected !== addon
          )
        );
        return;
      }
    }
    setSelectedAddons(selectedAddons.filter((t) => t !== addon));
  };

  const toggleAddonsSelection = (addon) => {
    if (!isAddonSelected(addon)) {
      addAddon(addon);
    } else {
      removeAddon(addon);
    }
  };

  const isAddonSelected = (addon) => {
    return selectedAddons.some((selected) => selected.id === addon.id);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col
          xs={24}
          sm={24}
          md={14}
          lg={16}
          xl={18}
          xxl={18}
          className="gutter-row"
        >
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "calc(100vh - 77px)",
            }}
            className="register_area_left_col"
          >
            <div style={{ paddingTop: "30px" }}>
              <h2>{t("pricing_plan")}</h2>
            </div>
            {/* user selection area */}
            <div
              style={{
                boxShadow: "0px 0px 3px #2828282E",
                backgroundColor: "#ffffff",
                padding: "10px",
                marginTop: "30px",
                borderRadius: "5px",
              }}
            >
              <Row
                gutter={{ xs: 8, sm: 16, md: 20, lg: 20, xl: 20 }}
                align="middle"
              >
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={13}
                  xl={10}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "20px",
                    }}
                  >
                    <span>
                      <h5>{t("number_of_users")}</h5>
                    </span>
                    <MinusOutlined
                      className="Solid_btn2"
                      onClick={() => {
                        usersCount > 1 && setUsersCount(usersCount - 1);
                      }}
                    />
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        border: "1px solid #FF4014",
                        borderRadius: "5px",
                        backgroundColor: "#ffffff",
                        minHeight: "32px",
                        minWidth: "46px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {usersCount}
                    </div>
                    <PlusOutlined
                      className="Solid_btn1"
                      onClick={() => setUsersCount(usersCount + 1)}
                    />
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={11}
                  xl={14}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    {findPlan(selectedPeriod) && (
                      <>
                        <span>
                          {userDiscount ? (
                            <small>
                              <Text
                                delete
                                type="danger"
                                style={{ fontWeight: "bold" }}
                              >
                                {currencyFormatter.format(
                                  selectedPeriod === PERIOD_DAILY &&
                                    findPlan(selectedPeriod).user_price
                                )}
                              </Text>
                            </small>
                          ) : (
                            ""
                          )}
                        </span>
                        <h5>
                          {currencyFormatter.format(
                            selectedPeriod === PERIOD_DAILY &&
                              findPlan(selectedPeriod).user_price -
                                findPercentage(
                                  findPlan(selectedPeriod).user_discount,
                                  findPlan(selectedPeriod).user_price
                                )
                          )}
                        </h5>
                        <span>
                          <small style={{ color: "#9654FF" }}>
                            {selectedPeriod === PERIOD_DAILY &&
                              t("day_per_user")}
                          </small>
                        </span>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {/* addon title */}
            <div style={{ padding: "15px 0" }}>
              <h5>{t("menu_addons")}</h5>
            </div>
            {/* addon grid */}
            <Row gutter={{ xs: 8, sm: 16, md: 15, lg: 15 }}>
              {addonsData.map((addon) => {
                return (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    key={addon.id}
                  >
                    <div
                      onClick={() => toggleAddonsSelection(addon)}
                      style={{
                        marginBottom: 20,
                        backgroundColor: "#ffffff",
                        transition: "all 0.2s ease-in-out",
                        borderRadius: "8px",
                        cursor: "pointer",
                        position: "relative",
                        boxShadow: "0px 4px 4px #2828281A",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{ paddingTop: "15px", paddingBottom: "5px" }}
                        >
                          <img
                            src={addon.icon}
                            alt="addon"
                            style={{ width: "50px", height: "auto" }}
                          />
                        </div>
                        <h6>{addon.name}</h6>
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#F1F1F1",
                            padding: "10px",
                            minHeight: "53px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {addon.total_trial_days > 0 ? (
                            addon.price !== "0.00" ? (
                              <>
                                <div>
                                  <Tag
                                    color="green"
                                    style={{
                                      fontSize: "18px",
                                      padding: "3px 15px",
                                    }}
                                  >
                                    {t("free")}
                                  </Tag>
                                </div>
                                <small>
                                  <Text delete type="danger">
                                    <CurrencyFormatter value={addon.price} />
                                  </Text>
                                </small>{" "}
                              </>
                            ) : (
                              <div>
                                <Tag
                                  color="green"
                                  style={{
                                    fontSize: "18px",
                                    padding: "3px 15px",
                                  }}
                                >
                                  {t("free")}
                                </Tag>
                              </div>
                            )
                          ) : addon.discount > 0 ? (
                            <>
                              <h3 style={{ color: "#9654FF" }}>
                                <CurrencyFormatter
                                  value={addon.price - addon.discount}
                                />{" "}
                                <small
                                  style={{ color: "#3C3C3C", fontSize: "10px" }}
                                >
                                  {t("daily")}
                                </small>
                              </h3>
                              <small>
                                <Text delete type="danger">
                                  <CurrencyFormatter value={addon.price} />
                                </Text>
                              </small>
                            </>
                          ) : (
                            <h3 style={{ color: "#9654FF" }}>
                              <CurrencyFormatter value={addon.price} />{" "}
                              <small
                                style={{ color: "#3C3C3C", fontSize: "10px" }}
                              >
                                {t("daily")}
                              </small>
                            </h3>
                          )}
                        </div>

                        <p
                          style={{
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            margin: "0",
                          }}
                        >
                          <CheckOutlined
                            style={{
                              fontSize: "14px",
                              color: "#00CFDE",
                              marginRight: "5px",
                            }}
                          />
                          {t("trial_days")} : {addon.total_trial_days}
                        </p>
                        {isAddonSelected(addon) && (
                          <div
                            style={{
                              padding: "6px",
                              position: "absolute",
                              top: "0",
                              right: "0",
                              backgroundColor: "#9654FF",
                              borderRadius: "0px 8px",
                            }}
                          >
                            <CheckOutlined
                              style={{
                                fontSize: "26px",
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={8}
          xl={6}
          xxl={6}
          className="gutter-row"
        >
          <div
            style={{
              boxShadow: "0px 3px 6px #00000029",
              backgroundColor: "#ffffff",
              marginTop: "10px",
              height: "calc(100vh - 87px)",
            }}
          >
            <div
              style={{ height: "calc(-386px + 100vh)", overflowY: "scroll" }}
            >
              {selectedAddons.length > 0 && (
                <div
                  style={{
                    padding: "20px 30px",
                  }}
                >
                  <h5 style={{ marginBottom: "10px", marginTop: "10px" }}>
                    {selectedAddons.length} {t("addon_selected")}
                  </h5>
                  {selectedAddons.map((addon) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px 0",
                          borderTop: "1px solid #D6D6D6",
                        }}
                        key={addon.id}
                      >
                        <div>
                          <CloseOutlined
                            style={{ color: "#ff0000" }}
                            onClick={() => toggleAddonsSelection(addon)}
                          />{" "}
                          {addon.name}
                        </div>
                        <div>
                          {addon.total_trial_days > 0 ? (
                            addon.price !== "0.00" ? (
                              <div>
                                <Tag color="green">{t("free")}</Tag>
                              </div>
                            ) : (
                              <div>
                                <Tag color="green">{t("free")}</Tag>
                              </div>
                            )
                          ) : addon.discount > 0 ? (
                            <>
                              <span style={{ color: "#9654FF" }}>
                                <CurrencyFormatter
                                  value={addon.price - addon.discount}
                                />{" "}
                                <small
                                  style={{ color: "#3C3C3C", fontSize: "10px" }}
                                >
                                  {t("daily")}
                                </small>
                              </span>
                            </>
                          ) : (
                            <span style={{ color: "#9654FF" }}>
                              <CurrencyFormatter value={addon.price} />{" "}
                              <small
                                style={{ color: "#3C3C3C", fontSize: "10px" }}
                              >
                                {t("daily")}
                              </small>
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <Alert
              message={t("all_prices_daily_basis")}
              type="info"
              showIcon
              style={{ borderRadius: "0" }}
            />
            <div style={{ backgroundColor: "#F1F1F1", padding: "20px 30px" }}>
              <h5 style={{ marginBottom: "10px", marginTop: "10px" }}>
                {t("selected_plan")}
              </h5>
              {userAmount ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0",
                    borderTop: "1px solid #D6D6D6",
                  }}
                >
                  {usersCount} {t("users")}
                  <span style={{ color: "#9654FF" }}>
                    {currencyFormatter.format(
                      selectedPeriod === PERIOD_DAILY &&
                        (userAmount -
                          findPercentage(userDiscount, userAmount)) *
                          usersCount
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 0",
                  borderTop: "1px solid #D6D6D6",
                }}
              >
                <span>{t("col_subtotal")}</span>
                {currencyFormatter.format(
                  selectedPeriod === PERIOD_DAILY &&
                    planAmount -
                      findPercentage(planDiscount, planAmount) +
                      (userAmount - findPercentage(userDiscount, userAmount)) *
                        usersCount
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 0",
                  borderTop: "1px solid #D6D6D6",
                }}
              >
                <strong>
                  {t("total_amount")}{" "}
                  <Tooltip title={t("we_only_charge_use_addon")}>
                    <InfoCircleOutlined
                      style={{
                        color: "#1890ff",
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                    />
                  </Tooltip>
                </strong>
                <span style={{ color: "#9654FF", fontWeight: "bold" }}>
                  {currencyFormatter.format(
                    selectedPeriod === PERIOD_DAILY &&
                      planAmount -
                        findPercentage(planDiscount, planAmount) +
                        (userAmount -
                          findPercentage(userDiscount, userAmount)) *
                          usersCount +
                        addonsTotal
                  )}
                </span>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                {userAmount ? (
                  <Button
                    className="Solid_btn2"
                    onClick={() =>
                      callbackSelection(
                        selectedPeriod,
                        usersCount,
                        selectedAddons
                      )
                    }
                    // className={displayWebView ? "button-webview" : ""}
                  >
                    {t("sign_up_free_trial")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <small
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                  color: "#9654FF",
                  fontSize: "10px",
                }}
              >
                * {t("pay")}{" "}
                {currencyFormatter.format(
                  selectedPeriod === PERIOD_DAILY &&
                    planAmount -
                      findPercentage(planDiscount, planAmount) +
                      (userAmount - findPercentage(userDiscount, userAmount)) *
                        usersCount +
                      addonsTotal
                )}{" "}
                + {t("VAT_once_trial_period_is_over")}
              </small>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Pricing;
